/**
 * @flow
 */
import React from 'react';
import {ColDef} from 'ag-grid-community';
import TuneIcon from 'mdi-react/TuneIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ViewListIcon from 'mdi-react/ViewListIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import PaymentIcon from 'mdi-react/PaymentIcon';
import PrinterIcon from 'mdi-react/PrinterIcon';
import ReceiptIcon from 'mdi-react/ReceiptIcon';

type GridActionType = 'edit' | 'delete' | 'detail' | 'addChild' | 'history' | 'print' | 'invoice';
type GridActionCallback = (action: GridActionType, data: Object) => void;
type GridActionDisableCallback = (action: GridActionType, data: Object) => boolean;
type GridActionHandler = (data: Object) => void;
type GridActionHandlerObject = {[key: GridActionType]: GridActionHandler};

interface GridActionColumnProps {
  data: Object;
  handlers: GridActionHandlerObject;
  isDisabled?: GridActionDisableCallback;
}

const actionIcons: {[key: GridActionType]: any} = {
  edit: TuneIcon,
  delete: DeleteIcon,
  detail: ViewListIcon,
  addChild: PlusIcon,
  history: PaymentIcon,
  print: PrinterIcon,
  invoice: ReceiptIcon,
};

const GridActionColumn = (props: GridActionColumnProps) => {
  return (
    <>
      {Object.entries(props.handlers).map(([action, handler]) => {
        const disabled = props.isDisabled ? props.isDisabled(action, props.data) === true : false;
        const Icon = actionIcons[action];
        if (disabled) {
          return <Icon key={action} size={18} color={'#c0c0c0'} className={'mx-1'} />
        } else {
          const color = action === 'delete' ? '#ff4861' : '#70bbfd';
          return (
            <a key={action} href={'/#'} onClick={(e) => {e.preventDefault(); handler(props.data)}}>
              <Icon size={18} color={color} className={'mx-1'} />
            </a>
          );
        }
      })}
    </>
  );
};

interface UseGridActionColumnParams {
  onAction: GridActionCallback;
  onDisable?: GridActionDisableCallback;
  actions?: GridActionType[];
  actionWidth?: number;
  headerName?: string;
}

export function useGridActionColumn(params: UseGridActionColumnParams): ColDef {
  const {onAction, onDisable, actions: actionsOrFunc, actionWidth, headerName} = params;
  const actions = (typeof actionsOrFunc === 'function' ? actionsOrFunc() : actionsOrFunc) ?? [];
  if (actions.length > 0) {
    const handlers: GridActionHandlerObject = {};
    for (const action of actions) {
      handlers[action] = (data) => onAction(action, data);
    }
    return {
      headerName: headerName ?? 'Actions',
      width: actionWidth,
      minWidth: actionWidth,
      cellRendererFramework: GridActionColumn,
      cellRendererParams: {
        handlers,
        isDisabled: onDisable,
      },
    };
  }
}

export default GridActionColumn;
