import header from './head51.jpg';
import logo from './logo@2x.png';
import coship from './logotype1-300x40.png';
import kakao from './2.jpg';
import logoFooter from './footer_logo.png';
import footerBg from './footer_bg.jpg';

const images = {
  header,
  logo,
  coship,
  kakao,
  logoFooter,
  footerBg,
};

export default images;
