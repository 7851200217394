/**
 * @flow
 */
import React from 'react';
import FormManager from '../lib/FormManager';
import {PrimaryButton} from '../components/buttons';
import usePrivacyModal from './usePrivacyModal';

const FN = {
  NAME: 'customerName',
  SERVICE_TYPE: 'serviceType',
  EMAIL: 'email',
  PHONE: 'phone',
  FROM_COUNTRY: 'fromCountry',
  ADDRESS: 'address',
  TO_COUNTRY: 'toCountry',
  REMARK: 'bigo',
  CONSENT: 'consent',
};

const SN = {
  NAME: 'customerName',
  SERVICE_TYPE: 'serviceType',
  EMAIL: 'email',
  PHONE: 'phone',
  FROM_COUNTRY: 'fromCountry',
  ADDRESS: 'address',
  TO_COUNTRY: 'toCountry',
  REMARK: 'bigo',
  CONSENT: 'consent',
};

export const serviceTypeOptions = [
  {value: '', label: '선택해 주세요.'},
  {value: '해외이사', label: '해외이사'},
  {value: '차량운송', label: '차량운송'},
  {value: '국제택배', label: '국제택배'},
  {value: '배송대행', label: '배송대행'},
  {value: '항공택배', label: '항공택배'},
  {value: '해운택배', label: '해운택배'},
];

class QuoteFormManager extends FormManager {
  FN = FN;
  onSave;
  constructor() {
    super({
      prefix: 'quote',
      fields: [
        {name: FN.NAME, serverName: SN.NAME, label: '고객명', required: true},
        {name: FN.SERVICE_TYPE, serverName: SN.SERVICE_TYPE, label: '서비스 종류', required: true, options: serviceTypeOptions, noDefOption: true},
        {name: FN.EMAIL, serverName: SN.EMAIL, label: '이메일', required: true},
        {name: FN.PHONE, serverName: SN.PHONE, label: '전화번호', required: true},
        {name: FN.FROM_COUNTRY, serverName: SN.FROM_COUNTRY, label: '출발국가/도시', required: true},
        {name: FN.ADDRESS, serverName: SN.ADDRESS, label: '현재주소', required: true},
        {name: FN.TO_COUNTRY, serverName: SN.TO_COUNTRY, label: '도착국가/도시', required: true},
        {name: FN.REMARK, serverName: SN.REMARK, label: '기타문의 사항', required: false, type: 'textarea'},
        {name: FN.CONSENT, serverName: SN.CONSENT, label: '', required: false, type: 'checkbox'},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        horizontal: false,
        onSubmit: () => this.onSave(),
      },
    });
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, , fields, errors] = this.renderParams;
    const {renderField: _r} = this;
    const modal = usePrivacyModal();
    const onPrivacyClick = (e) => {
      e.preventDefault();
      modal.open();
    };
    return (
      <div className={'w-full'}>
        <div className={'f-1_5em mb-8'}><b>온라인 신청</b></div>
        {/*<div className={'f-1_25em'}>*/}
        {/*  <b>전화 또는 실시간 채팅 상담이 용이하지 않으신 고객분들을 위해, COSHIP 온라인 견적을 제공해 드리고 있습니다.</b>*/}
        {/*</div>*/}
        <div className={'mb-20'}>
          아래 내용을 작성하여 보내주시면, 24시간 안에 (주말, 공휴일 제외) 연락드리겠습니다.
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.NAME)}</div>
          <div className={'flex-1'}>{_r(FN.SERVICE_TYPE)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.EMAIL)}</div>
          <div className={'flex-1'}>{_r(FN.PHONE)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.FROM_COUNTRY)}</div>
          <div className={'flex-1'}>{_r(FN.TO_COUNTRY)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1'}>{_r(FN.ADDRESS)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1'}>{_r(FN.REMARK)}</div>
        </div>
        <div className={'mb-8'}>
          <b>
            홈페이지의
            {' '}
            <a href={'#privacy'} onClick={onPrivacyClick}>개인정보 취급방침</a>
            {' '}
            에 동의 하시면 아래 동의함 글자를 클릭해주세요 **
          </b>
        </div>
        <div className={'flex middle mb-24'}>
          <div className={'w-36'}>{_r(FN.CONSENT)}</div>
          <label htmlFor={'quote-consent'}>동의함</label>
        </div>
        <div className={'flex center mt-12'}>
          <PrimaryButton type={'submit'} label={'SUBMIT'} onClick={() => {}} noMargin />
        </div>
        {modal.render()}
      </div>
    );
  };
  onValidate = (values) => {
    return values;
  }
}

export default QuoteFormManager;
