/**
 * @flow
 */
import React from 'react';
import FormManager from '../lib/FormManager';
import {PrimaryButton} from '../components/buttons';
import usePrivacyModal from './usePrivacyModal';

const FN = {
  NAME: 'name',
  NAME_ENG: 'nameEng',
  EMAIL: 'email',
  PASSWORD: 'password',
  PASSWORD2: 'password2',
  PHONE: 'phone',
  ADDRESS: 'address',
  CONSENT: 'consent',
};

const SN = {
  NAME: 'name',
  NAME_ENG: 'nameEng',
  EMAIL: 'email',
  PASSWORD: 'password',
  PASSWORD2: 'password2',
  PHONE: 'phone',
  ADDRESS: 'address',
  CONSENT: 'consent',
};

class RegisterFormManager extends FormManager {
  FN = FN;
  onSave;
  editMode;
  constructor() {
    super({
      prefix: 'quote',
      fields: [
        {name: FN.NAME, serverName: SN.NAME, label: '고객명 (한글)', required: true},
        {name: FN.NAME_ENG, serverName: SN.NAME_ENG, label: '고객명 (영문)', required: true},
        {name: FN.EMAIL, serverName: SN.EMAIL, label: '이메일', required: true},
        {name: FN.PASSWORD, serverName: SN.PASSWORD, label: '패스워드', required: true, type: 'password'},
        {name: FN.PASSWORD2, serverName: SN.PASSWORD2, label: '패스워드 (확인)', required: true, type: 'password'},
        {name: FN.PHONE, serverName: SN.PHONE, label: '전화번호', required: true},
        {name: FN.ADDRESS, serverName: SN.ADDRESS, label: '주소', required: false},
        {name: FN.CONSENT, serverName: SN.CONSENT, label: '', required: false, type: 'checkbox'},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        horizontal: false,
        onSubmit: () => this.onSave(this.getValues()),
      },
    });
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, , fields, errors] = this.renderParams;
    const {renderField: _r, getField: _f} = this;
    const modal = usePrivacyModal();
    const onPrivacyClick = (e) => {
      e.preventDefault();
      modal.open();
    };
    _f(FN.PASSWORD).required = this.editMode !== true;
    _f(FN.PASSWORD2).required = this.editMode !== true;
    _f(FN.EMAIL).disabled = this.editMode === true;
    return (
      <div className={'w-full'}>
        <div className={'f-1_5em mb-20'}><b>{this.editMode === true ? '회원정보수정' : '회원가입'}</b></div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.NAME)}</div>
          <div className={'flex-1'}>{_r(FN.NAME_ENG)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.EMAIL)}</div>
          <div className={'flex-1'}>{_r(FN.PHONE)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.PASSWORD)}</div>
          <div className={'flex-1'}>{_r(FN.PASSWORD2)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1'}>{_r(FN.ADDRESS)}</div>
        </div>
        {this.editMode !== true && (
          <div className={'mb-8'}>
            <b>
              홈페이지의
              {' '}
              <a href={'#privacy'} onClick={onPrivacyClick}>개인정보 취급방침</a>
              {' '}
              에 동의 하시면 아래 동의함 글자를 클릭해주세요 **
            </b>
          </div>
        )}
        {this.editMode !== true && (
          <div className={'flex middle mb-24'}>
            <div className={'w-36'}>{_r(FN.CONSENT)}</div>
            <label htmlFor={'quote-consent'}>동의함</label>
          </div>
        )}
        <div className={'flex center mt-12'}>
          <PrimaryButton type={'submit'} label={this.editMode ? '정보수정' : '회원가입'} onClick={() => {}} noMargin />
        </div>
        {modal.render()}
      </div>
    );
  };
  onValidate = (values) => {
    return values;
  }
}

export default RegisterFormManager;
