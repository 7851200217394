/**
 * @flow
 */
import React from 'react';
import axios from 'axios';
import {updateUser} from '../redux/reducers/userReducer';
import {showDialog} from '../redux/reducers/globalDialogReducer';

const useDev = window.location.host === 'localhost:3000' || window.location.host === 'my.homekling.com';
const API_URL_DEV = 'http://my.homekling.com';
const API_URL_PRD = useDev ? API_URL_DEV : 'https://my.coship.ca';

const API_URL = process.env.NODE_ENV === 'production' ? API_URL_PRD : API_URL_DEV;

class API {
  token;
  dispatch;
  history;
  async login(data) {
    const action = 'ReqLogin';
    // const timeDelta = new Date().getTimezoneOffset();
    const reqData = {action, data};
    const {data: res} = await this.sendList('login', reqData);
    this.token = res[0].token;
    localStorage.setItem('@token', res[0].token);
    localStorage.setItem('@user', JSON.stringify(res[0]));
    updateUser(this.dispatch, res[0]);
    return res[0];
  }
  async queryUser() {
    const {data} = await this.sendList('api/MenuList', {action: 'MenuList', data: {}});
    return data?.[0];
  }
  async queryToken() {
    const {data} = await this.sendList('token', {});
    if (data?.[0]?.token) {
      this.token = data[0].token;
      return true;
    } else {
      return false;
    }
  }
  async logout() {
    this.token = undefined;
    localStorage.removeItem('@token');
    localStorage.removeItem('@user');
  }
  async registerUser(data) {
    const action = 'SetCustomer';
    const reqData = {action, data};
    return this.sendCUD('partner/CUD', reqData);
  }
  async requestQuote(data) {
    const action = 'SetOnlineRequest';
    const reqData = {action, data};
    return this.sendCUD('partner/CUD', reqData);
  }
  async sendList(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    return res ?? {data: null, totalCount: 0};
  }
  async sendCUD(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    if (res?.data?.[0].ret === true) {
      return true;
    } else if (res?.data?.[0]) {
      return res.data[0];
    } else {
      return false;
    }
  }
  async send(path, reqData, doNotShowError) {
    try {
      //process.env.NODE_ENV !== 'production' && console.log('[API] REQ', path, reqData);
      const res = await axios.request({
        url: `${API_URL}/${path}`,
        method: 'POST',
        data: reqData,
        headers: {
          Authorization: `bearer ${this.token ?? localStorage.getItem('@token')}`,
        }
      });
      process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);
      const {err_message: message, data, totalcount: totalCount = 0} = res.data;
      if (!message) {
        return {data, totalCount};
      } else {
        const msg = message.toLowerCase();
        if (msg === 'login') {
          this.history.push('/member/login');
        } else if (msg.startsWith('error')) {
          if (doNotShowError !== true) {
            const messages = msg.split('#');
            if (messages.length === 2) {
              showDialog(`Something went wrong! (${messages[1]})`, 'System Error', 'danger')
            } else {
              showDialog('Something went wrong!', 'System Error', 'danger')
            }
          }
        } else {
          if (doNotShowError !== true) {
            showDialog(message, 'System Error', 'danger')
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        this.history.push('/member/login');
      } else {
        if (doNotShowError !== true) {
          showDialog('Something went wrong!', 'System Error', 'danger')
        }
      }
    }
  }
}

export default API;
