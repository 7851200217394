/**
 * @flow
 */
import React from 'react';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import Form, {renderField} from '../../components/Form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import {util, api} from '../../services/service';
import {resetAllDataGridInfo} from '../../redux/reducers/dataGridReducer';
import {useDispatch} from 'react-redux';
import {resetUser} from '../../redux/reducers/userReducer';
import {resetNoti} from '../../redux/reducers/notiReducer';
import Header from './Header';
import Footer from './Footer';

const fields = [
  {name: 'username', label: '이메일', required: true},
  {name: 'password', label: '패스워드', type: 'password', required: true},
  {name: 'trust', label: '로그인 유지', type: 'checkbox'},
];

const Login = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    localStorage.removeItem('@token');
    localStorage.removeItem('@user');
    resetAllDataGridInfo(dispatch);
    resetUser(dispatch);
    resetNoti(dispatch);
  }, []);
  const onToggleShowPassword = e => {
    e.preventDefault();
    const toggle = !showPassword;
    const passwordField = fields.find(i => i.name === 'password');
    passwordField.type = toggle ? 'text' : 'password';
    setShowPassword(toggle);
  };
  const passwordEndComponent = (
    <button
      className={`form__form-group-button${showPassword ? ' active' : ''}`}
      onClick={e => onToggleShowPassword(e)}
      type={'button'}
    >
      <EyeIcon />
    </button>
  );
  return (
    <div className={'_home-wrap'}>
      <Header />
      <div className={'_home-body'}>
        <div className={'flex center w-full my-48'}>
          <div className="account__card">
            <div className={'flex mb-32 f-1_5em'}>
              {/*<img src={logo} alt={'COSHIP'} className={'w-half'}/>*/}
              <b>로그인</b>
            </div>
            <Form
              fields={fields}
              doNotUseCard
              doNotUseButtons
              onSubmit={async values => {
                try {
                  const {username: loginId, password, trust: trustDevice} = values;
                  await api.login({loginId, password, trustDevice});
                  util.nav('/member');
                } catch (error) {
                  util.showError('Failed to login. Please try it again.');
                }
              }}
              render={(formik, fields, errors) => {
                return (
                  <>
                    {renderField(formik, 'username', fields, errors, <AccountOutlineIcon />)}
                    {renderField(formik, 'password', fields, errors, <KeyVariantIcon />, passwordEndComponent)}
                    <div className={'flex between middle w-full'}>
                      <div className={'flex-1'}>
                        {renderField(formik, 'trust', fields, errors)}
                      </div>
                      <div className={'flex-1 flex right mb-20'}>
                        <Link to={'/'}>패스워드 분실</Link>
                      </div>
                    </div>
                    <Button className={'w-full'} color={'primary'} type={'submit'}>로그인</Button>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
