import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {util} from '../../services/service';
import {Button, ButtonToolbar, Modal} from 'reactstrap';

const GDLG_OPEN = 'GDLG_OPEN';
const GDLG_CLOSE = 'GDLG_CLOSE';

export function showDialog(message, title, color, onConfirm?: () => void) {
  util.dispatch({type: GDLG_OPEN, message, title, color, onConfirm});
}

function hideDialog() {
  util.dispatch({type: GDLG_CLOSE});
}

export interface GlobalDialogState {
  open: boolean;
  title?: string;
  message?: string;
  color?: string;
  onConfirm?: () => void;
}

const globalDialogReducer = (state: GlobalDialogState = {open: false}, action) => {
  const {type, message, title, color, onConfirm} = action;
  switch (type) {
    case GDLG_OPEN:
      return {open: true, title, message, color, onConfirm};
    case GDLG_CLOSE:
      return {open: false};
    default:
      return state;
  }
};

export function GlobalDialog() {
  const dialog = useSelector(s => s['gdlg']);
  const {open, title, message, color, onConfirm} = dialog;
  const rtl = {direction: 'ltr'};
  const modalClass = 'modal-dialog--header';
  return (
    <Modal
      isOpen={open}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass}`}
      backdrop={'static'}
      keyboard={false}
      centered
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => hideDialog()} />
        {/*{header ? '' : Icon}*/}
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      <div className="modal__body">
        {message}
      </div>
      <ButtonToolbar className="modal__footer">
        {onConfirm && <Button className="modal_cancel" onClick={() => hideDialog()}>Cancel</Button>}
        {onConfirm && ' '}
        <Button className="modal_ok" color={color} onClick={() => {onConfirm && onConfirm(); hideDialog();}}>OK</Button>
      </ButtonToolbar>
    </Modal>
  );
}

export default globalDialogReducer;
