import {useSelector} from 'react-redux';
import {ColDef} from 'ag-grid-community';
import type {QueryListParams} from '../../services/API';

export const DATA_GRID_UPDATE = 'DATA_GRID_UPDATE';
export const DATA_GRID_RESET = 'DATA_GRID_RESET';
export const DATA_GRID_RESET_ALL = 'DATA_GRID_RESET_ALL';

export function updateDataGridInfo(dispatch, name, info: DataGridInfo) {
  dispatch({type: DATA_GRID_UPDATE, name, info});
}

export function resetDataGridInfo(dispatch, name) {
  dispatch({type: DATA_GRID_RESET, name});
}

export function resetAllDataGridInfo(dispatch) {
  dispatch({type: DATA_GRID_RESET_ALL});
}

export interface DataGridInfo extends QueryListParams {
  columns?: ColDef[];
  rows?: Object[];
  totalCount?: number;
  // qryText?: string;
  // orderBy?: string;
  // isDesc?: boolean;
  // rowCount?: number;
  // page?: number;
  // fromDate?: number;
  // toDate?: number;
  // cType?: 'U' | 'V' | 'B' // Customer 전용
}

export interface DataGridState {
  [key: string]: DataGridInfo;
}

export function useDataGridInfo(name): DataGridInfo {
  const dataGrid = useSelector(state => state['dataGrid']);
  return dataGrid[name];
}

export default function(state: DataGridState = {}, action) {
  const {type, name, info} = action;
  switch (type) {
    case DATA_GRID_UPDATE:
      return {...state, [name]: info};
    case DATA_GRID_RESET:
      return {...state, [name]: null};
    case DATA_GRID_RESET_ALL:
      return {};
    default:
      return state;
  }
}
