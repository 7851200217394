/**
 * @flow
 */
import React from 'react';
import {Link} from 'react-router-dom';
import images from '../../media/images';
import {useUser} from '../../redux/reducers/userReducer';

const headerLabels = {
  home: 'HOME',
  quote: '온라인 신청',
  login: '로그인',
  logout: '로그아웃',
  register: '회원가입',
  profile: '회원정보수정',
};

const Header = (props) => {
  const user = useUser();
  const signedIn = user?.id > 0;
  return (
    <div className={'flex between middle _home-hdr'}>
      <div className={'w-120 ml-12 p-12'}>
        <Link to={'/member'}>
          <img src={images.logo} alt={'CoShip'} />
        </Link>
      </div>
      <div className={'flex right middle'}>
        {user.id && <div className={'mr-24'}>{user.local_name ?? user.eng_name} 님</div>}
        <a href={'https://coship.ca'} className={'mr-24'}>{headerLabels.home}</a>
        <Link to={'/member/quote'} className={'mr-24'}>{headerLabels.quote}</Link>
        {!signedIn && <Link to={'/member/register'} className={'mr-24'}>{headerLabels.register}</Link>}
        {!signedIn && <Link to={'/member/login'} className={'mr-24'}>{headerLabels.login}</Link>}
        {signedIn && <Link to={'/member/profile'} className={'mr-24'}>{headerLabels.profile}</Link>}
        {signedIn && <Link to={'/member/login'} className={'mr-24'}>{headerLabels.logout}</Link>}
      </div>
    </div>
  );
};

export default Header;
