/**
 * @flow
 */
import React from 'react';
import {Button, Modal} from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import type {SimpleGridProps} from './SimpleGrid';
import {useSimpleGrid} from './SimpleGrid';
import FormManager from '../lib/FormManager';
import {util} from '../services/service';
import {RowClickedEvent} from 'ag-grid-community';

export interface SimpleModalProps {
  title: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  width?: number | string;
  centered?: boolean;
  hideCloseButton?: boolean;
  children?: React$Node;
  buttons?: {
    label: string,
    onClick: () => void,
    color?: string;
  }[];
}

const SimpleModal = (props: SimpleModalProps) => {
  return (
    <Modal isOpen={props.open ?? false} className={'modal-dialog--form'} centered={props.centered} style={{width: props.width}}>
      <div className={'card__title no-margin pt-20 px-20 flex between'}>
        <h5 className="bold-text">{props.title}</h5>
        {props.hideCloseButton !== true && (
          <a href={'/#close'} onClick={e => {e.preventDefault(); props.setOpen(false)}}>
            <CloseIcon color={'#606060'} />
          </a>
        )}
      </div>
      <div className={'w-full p-16 pb-24'}>
        {props.children}
        <div className={'flex center w-full'}>
          {props.buttons?.map(({label, onClick, color}, index) => {
            return (
              <Button key={`${label}-${index}`} color={color} size={'sm'} type={'button'} className={'no-margin mr-1'} onClick={onClick}>{label}</Button>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export interface SimpleGridModalProps extends SimpleModalProps {
  gridProps: SimpleGridProps;
}

interface UseSimpleModal {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  render: (children?: React$Node) => React$Node;
}

export function useSimpleModal(props: SimpleModalProps): UseSimpleModal {
  const [open, setOpen] = React.useState<boolean>();
  return {
    isOpen: open, // undefined | true | false
    open: () => setOpen(true),
    close: () => setOpen(false),
    render: (children) => <SimpleModal {...{...props, open, setOpen}}>{children ?? props.children}</SimpleModal>,
  };
}

type EditMode = 'add' | 'edit';

interface SimpleEditModalProps extends SimpleModalProps {
  form: FormManager;
  onSave: (mode: EditMode, data: any) => void;
}

interface UseSimpleEditModal extends UseSimpleModal {
  open: (mode: EditMode, data: any) => void;
}

export function useSimpleEditModal({form, onSave, ...props}: SimpleEditModalProps): UseSimpleEditModal {
  const [editData, setEditData] = React.useState();
  const extendedProps = {
    ...props,
    title: (editData?.mode === 'edit' ? 'EDIT ' : 'ADD ') + props.title,
    buttons: [
      {label: editData?.mode === 'edit' ? 'Edit' : 'Add', color: 'primary', onClick: () => onSave(editData?.mode, form.getValues(undefined, true))},
    ],
    children: form.renderForm(),
  };
  const modal = useSimpleModal(extendedProps);
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const {mode, data} = editData ?? {};
      if (mode === 'add') {
        form.clearValues();
      } else if (mode === 'edit') {
        form.setValues(data);
      }
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    ...editData,
    open: (mode, data) => {
      setEditData({mode, data});
      modal.open();
    },
  };
}

interface UseSimpleGridModal extends UseSimpleModal {
  setRows: (rows: Array) => void;
}

export function useSimpleGridModal({gridProps, ...props}: SimpleGridModalProps): UseSimpleGridModal {
  const [open, setOpen] = React.useState<boolean>();
  const grid = useSimpleGrid(gridProps);
  return {
    isOpen: open, // undefined | true | false
    open: () => setOpen(true),
    close: () => setOpen(false),
    setRows: (rows) => grid.setRows(rows),
    render: (children) => {
      return (
        <SimpleModal {...props} open={open} setOpen={setOpen}>
          {grid.render()}
          {children ?? props.children}
        </SimpleModal>
      );
    },
  };
}

/**
 * @example
 * const onQuery = async (queryData: Object) => {...};
 * const onCUD = async (mode, data) => {...};
 * const addOnly = false;
 * const form = myFormManager;
 * const modal = useCUDGridModal({
 *   title: 'Modal Title',
 *   width: 800,
 *   centered: true,
 *   gridProps: {
 *     columns: [...],
 *     height: 250,
 *     rows: [],
 *     className: 'additional classes',
 *   }
 * }, form, onQuery, onCUD, addOnly);
 */
export function useCUDGridModal(
  props: SimpleGridModalProps,
  form: FormManager,
  onQuery: (queryData: Object) => Promise<any[]>,
  onCUD: (mode: 'add' | 'edit' | 'delete', data: Object) => void,
  addOnly: boolean = false
) {
  const [queryData, setQueryData] = React.useState();
  const [mode, setMode] = React.useState();
  const modalRef = React.useRef();
  const queryListRef = React.useRef();
  function getButtons() {
    async function onEditClick() {
      const res = await onCUD('edit', form.getValues(undefined, true));
      if (res) {
        exitEditMode();
        await queryList()
      }
    }
    async function onAddClick() {
      const res = await onCUD('add', form.getValues(undefined, true));
      if (res) {
        exitEditMode();
        await queryList()
      }
    }
    if (mode === 'edit') {
      return [
        {label: 'Edit', color: 'primary', onClick: onEditClick},
        {label: 'Clear', onClick: () => exitEditMode()}
      ];
    } else {
      return [
        {label: 'Add', color: 'primary', onClick: onAddClick},
      ];
    }
  }
  function enterEditMode(data) {
    setMode('edit');
    form.setValues(data);
  }
  function exitEditMode() {
    setMode('add');
    form.clearValues();
  }
  async function queryList() {
    const res = await onQuery(queryData);
    if (Array.isArray(res)) {
      modalRef.current.setRows(res);
    } else {
      modalRef.current.setRows(res['data'] ?? []);
    }
  }
  const modal = useSimpleGridModal({
    ...props,
    buttons: getButtons(),
    gridProps: {
      ...props.gridProps,
      actions: addOnly ? ['delete'] : ['edit', 'delete'],
      actionWidth: 70,
      onAction: (action, data) => {
        if (action === 'edit') {
          enterEditMode(data);
        } else if (action === 'delete') {
          util.showConfirm('Are you sure to delete?', async () => {
            const res = await onCUD('delete', data);
            if (res) {
              exitEditMode();
              await queryList();
            }
          });
        }
      },
      agGridProps: {
        onRowClicked(e: RowClickedEvent) {
          if (!addOnly) {
            enterEditMode(e.data);
          }
        }
      },
    },
    children: form.renderForm(),
  });
  modalRef.current = modal;
  queryListRef.current = queryList;
  React.useEffect(() => {
    if (modal.isOpen === true) {
      queryList().catch(console.error);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (queryData) => {
      setMode('add');
      setQueryData(queryData);
      modal.open();
    },
    queryData,
    queryList,
  };
}

export default SimpleModal;
