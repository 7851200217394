/**
 * @flow
 */
import React from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import MainWrapper from './MainWrapper';
import {util, api} from '../../services/service';

import Login from '../Home/Login';
import Home from '../Home/Home';
import Quote from '../Home/Quote';
import Register from '../Home/Register';
import Profile from '../Home/Profile';

const Router = () => {
  const history = useHistory();
  util.history = history;
  api.history = history;
  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path={'/member/login'} component={Login} />
          <Route exact path={'/member/register'} component={Register} />
          <Route exact path={'/member/quote'} component={Quote} />
          <Route exact path={'/member/profile'} component={Profile} />
          <Route path={'/member'} component={Home} />
          <Route exact path={'/'} render={() => <Redirect to={'/member'} />} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
