/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import RegisterFormManager from '../../forms/RegisterFormManager';
import {api, util} from '../../services/service';
import {useUser} from '../../redux/reducers/userReducer';
import useCheckToken from '../../hooks/useCheckToken';

const registerForm = new RegisterFormManager();

const Register = () => {
  useCheckToken();
  const user = useUser();
  registerForm.onSave = async () => {
    const values = registerForm.getValues();
    let {name: localName, nameEng: engName, email, password: pwd, password2, phone, address: localAddress, consent} = values;
    consent = consent?.[0] === 'on';
    if (pwd !== password2) {
      util.showWarning('패스워드를 확인하세요.');
      return;
    }
    if (pwd.length < 6) {
      util.showWarning('패스워드를 6자리 이상 입력해 주세요.');
      return;
    }
    if (!consent) {
      util.showWarning('개인정보 취급방침에 동의해 주세요.');
      return;
    }
    if (!user.id) {
      const res = await api.queryToken();
      if (!res) {
        util.showError('회원가입에 실패하였습니다. 잠시후 다시 시도해 주세요.');
        return;
      }
    }
    const res = await api.registerUser({localName, engName, email, pwd, phone, localAddress});
    if (res) {
      util.showSuccess('회원가입이 완료되었습니다. 로그인 해주세요.');
      util.nav('/member/login');
    }
  };
  React.useEffect(() => {
    if (user.id) {
      util.nav('/member/profile');
    }
  }, []);
  return (
    <div className={'_home-wrap'}>
      <Header />
      <div className={'_home-body px-20'}>
        <Card style={{maxWidth: 900, margin: '20px auto'}}>
          <CardBody>
            {registerForm.renderForm()}
          </CardBody>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
