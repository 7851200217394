/**
 * @flow
 */
import React from 'react';
import {updateUser} from '../redux/reducers/userReducer';
import {useDispatch} from 'react-redux';

export default function useCheckToken() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const token = localStorage.getItem('@token');
    let user = localStorage.getItem('@user');
    user = user && JSON.parse(user);
    if (token && user) {
      updateUser(dispatch, user);
    }
  }, []);
}
