/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import RegisterFormManager from '../../forms/RegisterFormManager';
import {api, util} from '../../services/service';
import {updateUser, useUser} from '../../redux/reducers/userReducer';
import useCheckToken from '../../hooks/useCheckToken';
import {useDispatch} from 'react-redux';

const registerForm = new RegisterFormManager();

const Profile = () => {
  useCheckToken();
  const user = useUser();
  const dispatch = useDispatch();
  registerForm.editMode = true;
  registerForm.onSave = async () => {
    const values = registerForm.getValues();
    let {name: localName, nameEng: engName, email, password: pwd, password2, phone, address: localAddress} = values;
    if (pwd && pwd !== password2) {
      util.showWarning('패스워드를 확인하세요.');
      return;
    }
    const res = await api.registerUser({localName, engName, email, pwd, phone, localAddress, id: user.id});
    if (res) {
      util.showSuccess('회원 정보가 수정되었습니다.');
      const updatedUser = {...user, local_name: localName, eng_name: engName, email, phone, address: localAddress};
      updateUser(dispatch, updatedUser);
      localStorage.setItem('@user', JSON.stringify(updatedUser));
    }
  };
  React.useEffect(() => {
    if (!user.id) {
      util.nav('/member/register');
    } else {
      const {local_name: name, eng_name: nameEng, email, phone, address} = user;
      registerForm.setValuesFast({name, nameEng, email, phone, address});
    }
  }, []);
  return (
    <div className={'_home-wrap'}>
      <Header />
      <div className={'_home-body px-20'}>
        <Card style={{maxWidth: 900, margin: '20px auto'}}>
          <CardBody>
            {registerForm.renderForm()}
          </CardBody>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
