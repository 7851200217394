/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import QuoteFormManager from '../../forms/QuoteFormManager';
import {useUser} from '../../redux/reducers/userReducer';
import {api, util} from '../../services/service';
import useCheckToken from '../../hooks/useCheckToken';

const quoteForm = new QuoteFormManager();

const Quote = () => {
  useCheckToken();
  const user = useUser();
  quoteForm.onSave = async () => {
    const values = quoteForm.getValues();
    let {customerName, serviceType, email, phone, fromCountry, toCountry, address, bigo, consent} = values;
    consent = consent?.[0] === 'on';
    if (!consent) {
      util.showWarning('개인정보 취급방침에 동의해 주세요.');
      return;
    }
    if (!user.id) {
      const res = await api.queryToken();
      if (!res) {
        util.showError('온라인 신청에 실패하였습니다. 잠시후 다시 시도해 주세요.');
        return;
      }
    }
    const res = await api.requestQuote({customerName, serviceType, email, phone, fromCountry, toCountry, address, bigo});
    if (res) {
      util.showSuccess('온라인 신청이 완료 되었습니다. 24시간 안에 (주말, 공휴일 제외) 연락드리겠습니다.');
      util.nav('/member');
    }
  };
  React.useEffect(() => {
    if (user.id) {
      const {local_name: customerName, email, phone, address} = user;
      quoteForm.setValuesFast({customerName, email, phone, address});
    }
  }, []);
  return (
    <div className={'_home-wrap'}>
      <Header />
      <div className={'_home-body px-20'}>
        <Card style={{maxWidth: 900, margin: '20px auto'}}>
          <CardBody>
            {quoteForm.renderForm()}
          </CardBody>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Quote;
