import {useSelector} from 'react-redux';
import {ColDef} from 'ag-grid-community';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_RESET = 'USER_RESET';

export function updateUser(dispatch, user) {
  dispatch({type: USER_UPDATE, user});
}

export function resetUser(dispatch) {
  dispatch({type: USER_RESET});
}

export interface UserState {
  id: number;
  login_id: string;
  uname: string;
  auth: number;
  branch_id: number;
  token: string;
  menu: [string, [string, string][]][];
}

export function useUser(): UserState {
  return useSelector(state => state['user']);
}

export default function userReducer(state: UserState = {}, action) {
  const {type, user} = action;
  switch (type) {
    case USER_UPDATE:
      return {...user};
    case USER_RESET:
      return {};
    default:
      return state;
  }
}
