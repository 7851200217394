/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';

export default function usePrivacyModal() {
  return useSimpleModal({
    title: '개인정보 취급방침',
    width: 700,
    centered: true,
    children: (
      <div className={'px-24'}>
        <ul>
          <li>주식회사 캐나다쉬핑에이전시 고객 개인정보처리 방침 주식회사 캐나다쉬핑에이전시는 “화물운송주선업” 업무와 관련하여 개인정보 보호범등 관련 법률상의 개인정보보호 규정을 준수하며 다음과 같은 개인정보 처리 지침을 운영합니다.</li>
          <li>주식회사 캐나다쉬핑에이전시 고객 개인정보 수집 및 이용에 관한 사항 처리 목적</li>
          <ul>
            <li>주식회사 캐나다쉬핑에이전시는 다음의 목적을 위하여 개인정보를 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용목적이 변경되는 경우에는 개인정보보호법 제 18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.</li>
            <li>주식회사 캐나다쉬핑에이전시 영업상 화물운송게약의 고객 본인확인, 운송계약 안내, 운송계약 진행상황통지, 목적화물의 통관 및 배송지 확인, 고객 서비스 업무처리 등 서비스 제공을 목적으로 개인정보를 처리합니다.</li>
          </ul>
          <li>개인정보 처리항목 필수 항목 : 이름, 주민등록번호, 주소, 전화번호, 휴대전화번호, 이메일주소, 통관서류 (여권 및 비자, 취업증, 거주증 등)</li>
          <li>개인정보의 보유. 이용기간 보존이유 : 계약확인, 통관 및 배송안내, 고객만족조사, 감사프로그램, DM, 설문조사등</li>
          <li>보존기간 : 수집 목적이 종료되는 시점까지 고객 개인정보 보유 및 이용기간은 3년 입니다.</li>
          <li>수집하는 개인 정보 항목 주식회사 캐나다쉬핑에이전시의 처리.수집 개인정보는 상기 필수 항목 및 선택 항목에 표기된 항목입니다.</li>
          <li>동의를 거부할 권리</li>
          <ol>
            <li>주식회사 캐나다쉬핑에이전시 고객은 개인정보처리에 관한 동의를 거부할 권리가 있으며, 다만 개인정보 처리에 관한 동의거부, 개인정보의 처리, 정지, 삭제, 파기 요구시 주식회사 캐나다쉬핑에이전시의 계약접수.계약진행 상황 및 목적화물의 통관/배송 안내를 받을 수 없거나 화물 운송 계약의 진행이 불가능 할 수 있습니다.</li>
            <li>주식회사캐나다쉬핑에이전시 고객은 주식회사캐나다쉬핑에이전시에 대해 언제든지 개인정보 열람을 요구할 수 있고, 오류 등이 있을 경우 정정, 삭제, 처리, 정지를 요구할 권리가 있습니다.</li>
          </ol>
          <li>개인정보보호책임자에 관한 사항 주식회사캐나다쉬핑에이전시는 고객으로부터 얻게 된 개인정보처리에 관한 업무를 총괄해서 책임지고, 개인정보처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.</li>
        </ul>
        <p>본방침은 2017년 1월 1일부터 적용됩니다.</p>
      </div>
    ),
  });
}
