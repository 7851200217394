/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import images from '../../media/images';
import Header from './Header';
import Footer from './Footer';
import useCheckToken from '../../hooks/useCheckToken';

const Home = () => {
  useCheckToken();
  return (
    <div className={'_home-wrap'}>
      <Header />
      <div className={'_home-body'}>
        <div className={'_pos-rel'}>
          <div className={'_pos-abs _fit-parent flex-column center'} style={{justifyContent: 'center'}}>
            <div className={'flex center middle w-full'}>
              <div className={'mr-24'}>
                <img src={images.coship} alt={'CoShip'} width={220} height={29} />
              </div>
              <div className={'_home-bnr-l'}>고객센터</div>
            </div>
            <div className={'_home-bnr-m'}>캐나다 화물은 캐나다 쉬핑에 안심하고 맡겨주세요.</div>
          </div>
          <img src={images.header} width={'100%'} alt={'Banner'}/>
        </div>
        <div className={'_home-kakao'}>
          <img src={images.kakao} width={600} height={600} alt={'Kakao Talk'} />
        </div>
        <div className={'flex center top wrap px-20'}>
          <Card className={'flex-1 mx-20'} style={{minWidth: 300}}>
            <CardBody className={'flex-column center'}>
              <b className={'mb-20'}>캐나다 밴쿠버 본사 (CANADA ORIENT SHIPPING)</b>
              <div>대표전화: 604-455- 1000</div>
              <div>Toll Free: 1-855- 604-4551</div>
              <div>인터넷 전화: 070-7525- 7791/2</div>
              <div>주소: 1-20120 102B AVE, LANGLEY, BC, CANADA V1M 3G9</div>
              <div>영업시간: 캐나다 본사 ( 월~금 9am ~6pm , PST 기준)</div>
            </CardBody>
          </Card>
          <Card className={'flex-1 mx-20'} style={{minWidth: 300}}>
            <CardBody className={'flex-column center'}>
              <b className={'mb-20'}>캐나다 토론토 지사 (TORONTO CANADA ORIENT SHIPPING)</b>
              <div>대표전화: 647-380-1000</div>
              <div>주소: 275 Export Boulevard Mississauga, Toronto</div>
              <div>Ontario, Canada L5S 1Y4</div>
              <div>영업시간: 캐나다 본사 ( 월~금 9am ~6pm , EST 기준)</div>
            </CardBody>
          </Card>
          <Card className={'flex-1 mx-20'} style={{minWidth: 300}}>
            <CardBody className={'flex-column center'}>
              <b className={'mb-20'}>한국 지사 (CANADA SHIPPING AGENCY)</b>
              <div>대표전화: 02-2043- 5000</div>
              <div>주소: 서울시 강서구 양천로 551-17 한화비즈메트로 1차 602호</div>
              <div>영업시간 : 한국지사 ( 월~금 9am~6pm )</div>
            </CardBody>
          </Card>
        </div>
        <div className={'flex center mb-48'}>
          * 업무시간 외, 주말 및 공휴일에는 상담시간이 다소 지연될 수 있습니다.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
