/**
 * @flow
 */
import React from 'react';
import images from '../../media/images';

const Footer = (props) => {
  return (
    <div className={'flex between middle wrap w-full _home-ftr'}>
      <div className={'m-20'} style={{width: 100}}>
        <img src={images.logoFooter} alt={'Logo'} />
      </div>
      <div className={'mx-20 _home-ftr-label'}>
        <div>(c) {new Date().getFullYear()} COSHIP - All Rights Reserved</div>
      </div>
    </div>
  );
};

export default Footer;
